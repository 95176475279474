@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  display: flex;
  width: 100%;
  gap: 24px;
  padding: 24px;
  border: 2px solid $black;
  background-color: $white;
  text-transform: uppercase;
  transition: all 0.1s;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  min-width: 90px;
  color: $black;
  @include pointer;
  overflow: hidden;

  @include vp-840 {
    padding: 16px;
    min-width: 45px;
  }

  span {
    font-size: 22px;
    font-weight: 900;
    line-height: 28px;
    transition: all 0.3s;
  }

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    transition: all 0.3s;
  }

  &:active {
    background-color: $gray;
  }
}

.wrapper {
  width: 100%;
  cursor: $cursor-pointer;
  background-color: $black;
  position: relative;
  height: 100%;
}

.dark {
  background-color: $gray-dark;

  .button {
    background-color: $black;
    color: $white;

    &:active {
      background-color: $black-light;
      border: 2px solid $black-light;
    }
  }
}

.hovered {
  transform: translate(10px, -10px);
  z-index: 1;

  @include vp-1023 {
    transform: translate(0, 0);
  }
}

.clicked {
  transform: translate(5px, -5px);
}

div.fit {
  width: fit-content;
}

.animatedIcon {
  overflow: hidden;

  svg {
    position: absolute;
    height: 28px;
    width: fit-content;
    top: 50%;
    right: 10%;
    transform: translateX(500%) translateY(-50%);
  }
}

.animatedIcon.hovered {
  svg {
    transform: translateX(0%) translateY(-50%);
  }

  span {
    padding-right: 30px;
  }
}

.changed {
  animation: change 0.3s ease-in-out;
}

@keyframes change {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
