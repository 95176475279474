@import 'src/styles/variables';
@import 'src/styles/mixins';

.slider {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(100%);
  height: 100%;
  line-height: 24px;
  font-size: 18px;
  font-weight: 900;
}

.active {
  opacity: 1;
  transform: translateY(0);
}

.inactive {
  opacity: 0;
  transform: translateY(-100%);
}
