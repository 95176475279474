@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  @include spacing;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include vp-1368 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  margin-bottom: 64px;

  @include vp-840 {
    margin-bottom: 24px;
  }
}

.content {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-end;

  @include vp-1368 {
    width: 100%;
  }
}

.block {
  border: 2px solid $black;
  padding: 24px;
  background-color: $white;
  text-align: center;
  width: 100%;
  line-height: 24px;
  font-size: 18px;
  font-weight: 900;

  @include vp-840 {
    padding: 16px;
  }

  &Black {
    background-color: $black;
    color: $white;
  }

  &Small {
    font-size: 18px;
  }

  &Button {
    height: 76px;

    @include vp-840 {
      height: 60px;
    }
  }
}

.slider {
  padding: 24px;
  border: 2px solid $black;
  text-align: center;
  background-color: $black;
  height: 76px;
  color: $white;
  width: 100%;

  @include vp-840 {
    padding: 16px;
    height: 60px;
  }
}

.top {
  display: flex;
  gap: 8px;
  width: 100%;

  @include vp-840 {
    flex-direction: column;
  }

  &Text {
    border: 2px solid $black;
    padding: 24px;
    background-color: $white;
    width: 462px;
    min-width: 320px;
    height: 328px;
    line-height: 24px;
    font-size: 18px;

    @include vp-1368 {
      height: auto;
    }

    @include vp-840 {
      width: 100%;
    }
  }

  &Blocks {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 8px;
    width: 300px;

    @include vp-1368 {
      width: 100%;
    }
  }
}

.bottom {
  display: flex;
  gap: 8px;
  width: 100%;

  @include vp-840 {
    flex-direction: column;
  }

  &Main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 462px;

    @include vp-840 {
      width: 100%;
    }
  }

  &Blocks {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 300px;

    @include vp-1368 {
      width: 100%;
    }
  }

  &Text {
    border: 2px solid $black;
    padding: 24px;
    background-color: $white;
    width: 100%;
    height: 304px;
    line-height: 24px;
    font-size: 18px;

    @include vp-1368 {
      height: auto;
    }
  }
}

.button {
  span {
    font-size: 18px;
  }
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 240px;
  height: 240px;
  z-index: -1;
  animation: fly 7s ease-in-out infinite;

  @include vp-840 {
    width: 120px;
    height: 120px;
    margin: 0;
    bottom: 30px;
    animation: flyMob 7s ease-in-out infinite;
  }
}

@keyframes fly {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-15%) rotate(-5deg);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes flyMob {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(10%) rotate(5deg);
  }
  100% {
    transform: translateY(0%);
  }
}

.empty {
  height: 160px;

  @include vp-840 {
    height: 100px;
  }
}
