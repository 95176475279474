@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: grid;
  place-items: center;
  min-height: 250px;
  height: 220px;
  aspect-ratio: 1;
  overflow: hidden;
  font-size: 16px;
  position: relative;
  text-transform: uppercase;
  animation: spin 30s infinite linear;
}

.char {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}
