@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  @include spacing;
  min-height: 400px;
}

.title {
  @include spacing-sm;
}

.content {
  display: flex;
  gap: 8px;

  @include vp-1023 {
    flex-direction: column;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.buttons {
  display: flex;
  gap: 8px;
  height: 76px;

  @include vp-840 {
    flex-direction: column;
  }
}

.arrow {
  fill: $black;
}
