@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  text-align: center;
  height: 100%;
  overflow: hidden;
}

.available {
  width: 100%;
  border: 2px solid $black;
  padding: 24px;
  background-color: $white;
}

.time {
  display: flex;
  flex-direction: column;

  &Text {
    font-size: 16px;
    font-weight: 700;
  }

  &Numbers {
    font-size: 24px;
    font-weight: 900;
  }
}

.days {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  width: 100%;
  gap: 8px;

  span {
    font-size: 24px;
    font-weight: 900;
  }
}

.current {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 2px solid $black;
  background-color: $white;

  &Time {
    font-size: 24px;
    font-weight: 900;
  }

  &Day {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 900;
  }
}

.today {
  font-size: 16px;
  font-weight: 700;
}

div.marquee {
  padding: 24px 0;
  width: 100%;
  background-color: $white;
  background-color: $black;
  color: $white;
  border: 2px solid $black;
}

.now {
  font-weight: 900;
  font-size: 54px;
  text-transform: uppercase;
  margin-right: 12px;
}
