@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  z-index: 100;
  bottom: 44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 440px;
  padding: 12px;
  border: 2px solid $black;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  opacity: 0;

  @include vp-495 {
    width: calc(100% - 48px);
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  max-width: 56px;
  min-width: 56px;
  height: 56px;
  cursor: $cursor-pointer;
  background-color: $white;
  color: $black;
  white-space: nowrap;
  transition: all 0.3s;
  padding: 0;
  overflow: hidden;
  border: 2px solid $black;

  &:hover {
    background-color: $gray;
  }

  @include vp-495 {
    width: 56px;
  }
}

.visible {
  opacity: 1;
}

.title {
  @include vp-495 {
    display: none;
  }
}

.active {
  background-color: $black;
  color: $white;
  width: fit-content;
  max-width: 200px;
  cursor: $cursor-pointer;
  padding: 0 16px;

  &:hover {
    background-color: $black;
  }

  @include vp-495 {
    width: 56px;
    padding: 0;
  }
}

.icon {
  height: 36px;
  width: 36px;
  object-fit: contain;
  color: $black;
  fill: $black;

  &Active {
    color: $white;
    fill: $white;
  }
}
