@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 20px;
  z-index: 100;
  margin-bottom: 64px;
  transition: all 0.3s;

  @include vp-495 {
    margin-bottom: 36px;
  }
}

div.hidden {
  transform: translateY(-300%);
}

.buttons {
  display: flex;
  height: 90px;
  transition: all 0.5s;

  @include vp-840 {
    height: 60px;
  }
}

button.button {
  width: 90px;

  span {
    font-size: 32px;
    font-weight: 900;
  }

  @include vp-840 {
    span {
      font-size: 24px;
    }

    width: 60px;
  }
}

button.cv {
  width: 90px;

  span {
    font-size: 32px;
    font-weight: 900;
  }

  @include vp-840 {
    width: 60px;

    span {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

button.contact {
  width: 400px;

  @include vp-840 {
    line-height: 28px;
    width: 200px;
  }

  @include vp-840 {
    display: none;
  }
}

.contactMob {
  display: none;

  @include vp-840 {
    display: flex;
    width: 60px;
  }
}

button.contactMob {
  padding: 12px;
}

.moon {
  fill: $white;
}

.phone {
  fill: $black;
}
