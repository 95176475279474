@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 462px;

  @include vp-840 {
    min-width: auto;
  }
}

.chat {
  border: 2px solid $black;
  padding: 24px 8px 66px 8px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include vp-1023 {
    padding: 24px 0px;
  }
}

.message {
  width: 200px;
  height: 100px;
  fill: $black;
  color: $black;
  object-fit: fill;

  &Me {
    transform: scaleX(-100%);
    align-self: flex-end;
  }
}

.input {
  border: 2px solid $black;
  padding: 24px;
  background-color: $white;
  font-size: 18px;
  line-height: 26px;
  height: 76px;

  @include vp-840 {
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
    height: 56px;
  }
}

@keyframes flip {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
