@import 'src/styles/variables';

@mixin vp-1919 {
  @media (max-width: (1919px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: (1439px)) {
    @content;
  }
}

@mixin vp-1368 {
  @media (max-width: (1368px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: (1279px)) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: (1023px)) {
    @content;
  }
}

@mixin vp-840 {
  @media (max-width: (840px)) {
    @content;
  }
}

@mixin vp-495 {
  @media (max-width: (495px)) {
    @content;
  }
}

@mixin pointer {
  cursor: $cursor-pointer;

  &:active {
    cursor: $cursor-click;
  }
}

@mixin page {
  width: 100%;
  margin: 0 auto;
  max-width: 1380px;

  @include vp-1368 {
    padding: 0 24px;
  }
}

@mixin spacing {
  margin-bottom: 200px;

  @include vp-840 {
    margin-bottom: 64px;
  }
}

@mixin spacing-sm {
  margin-bottom: 50px;

  @include vp-495 {
    margin-bottom: 24px;
  }
}
