@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  font-weight: 900;
  font-size: 90px;
  color: $black;
  line-height: 110px;

  @include vp-1023 {
    font-size: 70px;
    line-height: 60px;
  }

  @include vp-840 {
    font-size: 54px;
    line-height: 48px;
  }
}
