@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;

  @include vp-840 {
    margin-bottom: 64px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: 100%;

  @include vp-840 {
    flex-direction: column;
  }
}

.link {
  text-decoration: none;
  color: $black;
  font-size: 16px;
  font-weight: 700;
  @include pointer;
  color: $white;
}

button.button {
  min-height: 100%;

  span {
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
  }
}

div.button {
  min-height: 100%;
}

.rights {
  font-size: 16px;
  font-weight: 700;
  color: $white;
  padding: 24px;
  border: 2px solid $black;
  background-color: $black;
  text-align: center;
  min-width: 440px;
  white-space: wrap;
  display: flex;
  align-items: center;
  justify-content: center;

  @include vp-1023 {
    min-width: 300px;
  }

  @include vp-840 {
    min-width: auto;
    width: 100%;
    padding: 16px;
    order: -1;
  }
}

.arrow {
  fill: $black;
}
