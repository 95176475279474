@import 'src/styles/variables';
@import 'src/styles/mixins';

.main {
  @include page;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 64px 0;

  @include vp-840 {
    padding: 0 24px 24px 24px;
  }
}

h1.title {
  @include spacing-sm;

  text-overflow: ellipsis;
  overflow: hidden;

  @include vp-840 {
    font-size: 48px;
  }

  @include vp-495 {
    font-size: 32px;
  }
}

.text {
  @include spacing-sm;
  width: 100%;
}

.button {
  width: 400px;
  height: 70px;

  @include vp-840 {
    width: 100%;
  }
}

.arrow {
  fill: $black;
}
