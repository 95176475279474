@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  @include spacing;
  display: flex;
  flex-direction: column;
}

.companies {
  display: flex;
  gap: 24px;

  @include vp-1368 {
    flex-direction: column;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.row {
  display: flex;
  gap: 24px;

  @include vp-840 {
    flex-direction: column;
  }
}

div.cloud {
  width: 400px;
  height: 500px;

  @include vp-1368 {
    width: 100%;
    height: 400px;
  }

  @include vp-495 {
    height: fit-content;
  }
}

div.startup {
  width: 100%;
  height: 238px;

  @include vp-840 {
    width: 100%;
  }

  @include vp-495 {
    height: fit-content;
  }
}

div.freelance {
  width: 100%;
  height: 238px;

  @include vp-840 {
    width: 100%;
  }

  @include vp-495 {
    height: fit-content;
  }
}

div.air {
  width: 460px;
  height: 238px;

  @include vp-840 {
    width: 100%;
  }

  @include vp-495 {
    height: fit-content;
  }
}

div.now {
  width: 350px;
  height: 238px;

  @include vp-840 {
    width: 100%;
  }

  @include vp-495 {
    height: fit-content;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
}

.symbol {
  color: $gray-dark;
}

.title {
  @include spacing-sm;
}
